// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScheduleBorderedAvatar-module__Br1zRwV8JxNdLrrI8zBl{position:relative}.ScheduleBorderedAvatar-module__X44HmDjJomI_HdJEhBgG{position:absolute;top:0px;left:0px;z-index:-1}.ScheduleBorderedAvatar-module__swIEvAnExSAZ0Lwruryp{position:relative;top:-8px}", "",{"version":3,"sources":["webpack://./components/ScheduleBorderedAvatar/ScheduleBorderedAvatar.module.scss"],"names":[],"mappings":"AAAA,qDACE,iBAAA,CAEF,qDACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,UAAA,CAEF,qDACE,iBAAA,CACA,QAAA","sourcesContent":[".root {\n  position: relative;\n}\n.avatar {\n  position: absolute;\n  top: 0px;\n  left: 0px;\n  z-index: -1;\n}\n.icon {\n  position: relative;\n  top: -8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ScheduleBorderedAvatar-module__Br1zRwV8JxNdLrrI8zBl",
	"avatar": "ScheduleBorderedAvatar-module__X44HmDjJomI_HdJEhBgG",
	"icon": "ScheduleBorderedAvatar-module__swIEvAnExSAZ0Lwruryp"
};
export default ___CSS_LOADER_EXPORT___;
