// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SourceCode-module__m7_uKyBfgPHZXPdeqCay{position:relative;width:100%}.SourceCode-module__m7_uKyBfgPHZXPdeqCay:hover .SourceCode-module__aKm5zEOfOz0C_VfDKH_P{opacity:1}.SourceCode-module__jqe5OoEDEAF8ZmMmVPq4{overflow-y:auto;border-radius:2px;padding:12px 60px 12px 20px}.SourceCode-module__odse2QHgVWTl5i20MKXg{max-height:400px}.SourceCode-module__r2ojZK26G8CSTu6Pn4Jj,.SourceCode-module__aKm5zEOfOz0C_VfDKH_P{position:absolute;top:15px;right:15px;transition:opacity .2s ease}.SourceCode-module__aKm5zEOfOz0C_VfDKH_P{opacity:0}", "",{"version":3,"sources":["webpack://./components/SourceCode/SourceCode.module.scss"],"names":[],"mappings":"AAAA,yCACE,iBAAA,CACA,UAAA,CAEA,wFACE,SAAA,CAIJ,yCACE,eAAA,CACA,iBAAA,CACA,2BAAA,CAEA,yCACE,gBAAA,CAIJ,kFAEE,iBAAA,CACA,QAAA,CACA,UAAA,CACA,2BAAA,CAEF,yCACE,SAAA","sourcesContent":[".root {\n  position: relative;\n  width: 100%;\n\n  &:hover .copyButton {\n    opacity: 1;\n  }\n}\n\n.scroller {\n  overflow-y: auto;\n  border-radius: 2px;\n  padding: 12px 60px 12px 20px;\n\n  &--maxHeight {\n    max-height: 400px;\n  }\n}\n\n.copyIcon,\n.copyButton {\n  position: absolute;\n  top: 15px;\n  right: 15px;\n  transition: opacity 0.2s ease;\n}\n.copyButton {\n  opacity: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "SourceCode-module__m7_uKyBfgPHZXPdeqCay",
	"copyButton": "SourceCode-module__aKm5zEOfOz0C_VfDKH_P",
	"scroller": "SourceCode-module__jqe5OoEDEAF8ZmMmVPq4",
	"scroller--maxHeight": "SourceCode-module__odse2QHgVWTl5i20MKXg",
	"copyIcon": "SourceCode-module__r2ojZK26G8CSTu6Pn4Jj"
};
export default ___CSS_LOADER_EXPORT___;
